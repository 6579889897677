.__sample-videos-swiper {
  .swiper {
    .swiper-wrapper {
      align-items: stretch;
      padding-bottom: 5px;

      .swiper-slide {
        display: flex;
        height: unset;
      }
    }
  }
}